import type { Chain, PublicClient, Transport } from "viem"
import { createPublicClient, defineChain, http } from "viem"
import { avalanche, base, mainnet, optimism, polygon, sepolia, zora } from "viem/chains"

const apechain = defineChain({
  id: 33139,
  name: "ApeChain",
  network: "apechain",
  nativeCurrency: {
    name: "APE",
    symbol: "APE",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://apechain.calderachain.xyz/http"],
    },
    public: {
      http: ["https://apechain.calderachain.xyz/http"],
    },
  },
  blockExplorers: {
    default: { name: "Blockscout", url: "https://apechain.calderaexplorer.xyz" },
  },
})

const xai = defineChain({
  id: 660279,
  name: "Xai",
  nativeCurrency: {
    decimals: 18,
    name: "Xai",
    symbol: "XAI",
  },
  network: "Xai",
  rpcUrls: {
    public: { http: ["https://xai-chain.net/rpc"] },
    default: { http: ["https://xai-chain.net/rpc"] },
  },
  blockExplorers: {
    default: { name: "Explorer", url: "https://explorer.xai-chain.net" },
  },
})

export const SUPPORTED_CHAINS = [
  apechain,
  avalanche,
  polygon,
  optimism,
  base,
  mainnet,
  sepolia,
  zora,
  xai,
] as const
export type SupportedChainId = (typeof SUPPORTED_CHAINS)[number]["id"]
export const SUPPORTED_CHAIN_IDS: Set<SupportedChainId> = new Set(
  SUPPORTED_CHAINS.map((c) => c.id),
)

export function getPublicClient(
  chainId: SupportedChainId,
  credentials?: {
    infura?: {
      apiKey: string
    }
  },
): PublicClient<Transport, Chain> {
  switch (chainId) {
    case apechain.id:
      return createPublicClient({ chain: apechain, transport: http() })
    case avalanche.id:
      return createPublicClient({
        chain: avalanche,
        transport: http(
          credentials?.infura?.apiKey
            ? `https://avalanche-mainnet.infura.io/v3/${credentials.infura.apiKey}`
            : undefined,
        ),
      })
    case polygon.id:
      return createPublicClient({
        chain: polygon,
        transport: http(
          credentials?.infura?.apiKey
            ? `https://polygon-mainnet.infura.io/v3/${credentials.infura.apiKey}`
            : undefined,
        ),
      })
    case optimism.id:
      return createPublicClient({
        chain: optimism,
        transport: http(
          credentials?.infura?.apiKey
            ? `https://optimism-mainnet.infura.io/v3/${credentials.infura.apiKey}`
            : undefined,
        ),
      }) as PublicClient<Transport, Chain>
    case base.id:
      return createPublicClient({ chain: base, transport: http() }) as PublicClient<
        Transport,
        Chain
      >
    case mainnet.id:
      return createPublicClient({
        chain: mainnet,
        transport: http(
          credentials?.infura?.apiKey
            ? `https://mainnet.infura.io/v3/${credentials.infura.apiKey}`
            : undefined,
        ),
      })
    case sepolia.id:
      return createPublicClient({
        chain: sepolia,
        transport: http(
          credentials?.infura?.apiKey
            ? `https://sepolia.infura.io/v3/${credentials.infura.apiKey}`
            : undefined,
        ),
      })
    case xai.id:
      return createPublicClient({ chain: xai, transport: http() })
    case zora.id:
      return createPublicClient({ chain: zora, transport: http() }) as PublicClient<
        Transport,
        Chain
      >
    default: {
      const _exhaustiveCheck: never = chainId
      throw new Error(`Unsupported chain ${_exhaustiveCheck}`)
    }
  }
}

export function getBlocksPerDay(chainId: SupportedChainId): number {
  switch (chainId) {
    case polygon.id:
      return 40_000 // based on https://polygonscan.com/chart/blocks
    case optimism.id:
    case base.id:
    case zora.id:
    case avalanche.id:
      return 43_200 // assumes block every 2 seconds
    case mainnet.id:
    case sepolia.id:
    case apechain.id:
    case xai.id:
      return 7_200 // assumes block every 12 seconds
  }
}

export function getViemChain(chainId: SupportedChainId): Chain {
  switch (chainId) {
    case apechain.id:
      return apechain
    case avalanche.id:
      return avalanche
    case polygon.id:
      return polygon
    case optimism.id:
      return optimism
    case base.id:
      return base
    case mainnet.id:
      return mainnet
    case sepolia.id:
      return sepolia
    case xai.id:
      return xai
    case zora.id:
      return zora
  }
}

export function getOnchainBlockNumber(chainId: SupportedChainId) {
  switch (chainId) {
    // All Arbitrum Orbit chains use mainnet block numbers: https://docs.arbitrum.io/build-decentralized-apps/arbitrum-vs-ethereum/block-numbers-and-time#example
    case apechain.id:
    case xai.id:
      return getPublicClient(mainnet.id).getBlockNumber()
    default:
      return getPublicClient(chainId).getBlockNumber()
  }
}
